<template>
    <header>
        <div class="header_content_block w-100">
            <div class="header_menu_block d-flex w-100 header-content container-xl">
                <div class="header_logo_block">
                    <NuxtLink :to="localePath('/')" class="header_logo_container">
                        <img class="header_logo_white" :src="runtimeConfig.public.STATIC_BASE_URL + getLogoType('desktop').path" alt="" />
                        <img class="header_logo_blue" :src="runtimeConfig.public.STATIC_BASE_URL + getLogoType('mobile').path" alt="" />
                    </NuxtLink>
                </div>
                <div class="header_menu_block d-flex align-content-center justify-content-end">
                    <div class="header_menu_burger_block" @click="openMenu()">
                        <img src="@/assets/images/default_menu_icons.svg" alt="" />
                    </div>
                    <div
                        class="header_menu_group_block"
                        :class="{
                            header_menu_group_active: menuStatus,
                        }"
                    >
                        <div class="header_menu_mobile_block-row container-xl">
                            <div class="header_menu_mobile_row header_menu_mobile_row container-xl">
                                <div class="header_menu_mobile_logo_block w-50">
                                    <img :src="runtimeConfig.public.STATIC_BASE_URL + getLogoType('desktop').path" alt="" class="header_menu_mobile_logo" />
                                </div>
                                <div class="header_menu_close_button_block">
                                    <div class="header_menu_close_button">
                                        <div href="#" class="header_menu_close_button" @click="closeMenu()">
                                            <img class="header_menu_close_button_image" src="@/assets/images/icons/arrow_done.svg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <default-input
                                v-model="searchFormCode"
                                :clear-button="true"
                                :title="true"
                                :class="{
                                    header_content_info_forms: true,
                                    'loading loading--small': searchFormCodeLoading,
                                    search_by_code_form_result_error_input: searchFormCodeResult.success === false,
                                }"
                                mask="#### #### ####"
                                @keyup.enter="searchProductCode(searchFormCode)"
                            >
                                <template #container-right>
                                    <img class="default_input_search_button" src="@/assets/images/search_icon.svg" alt="" @click="searchProductCode(searchFormCode)" />
                                </template>
                            </default-input>
                        </div>
                        <div class="header_menu_block_link">
                            <a class="header_menu_link" @click="() => openModalRegionSelect()">
                                <div class="header_menu_icons_block header_city_select_icon">
                                    <img src="@/assets/images/default_arrow_icon.svg" alt="" class="header_menu_icons" />
                                </div>
                                <span class="header_city_select_mobile_prefix">{{ countryStore.currentCity.name }} </span>
                                <span class="header_city_select_selected_city">
                                    {{ computedSelectedCity }}
                                </span>
                                <div class="header_menu_icons_block header_city_select_icon">
                                    <img src="@/assets/images/arrow_done.svg" alt="" class="header_menu_icons header_menu_icons_arrow_done" />
                                </div>
                            </a>
                        </div>
                        <div class="header_menu_block_link_mobile header_menu_block_link_list container-xl">
                            <div class="header_menu_block_lang_title">{{ $t('choose_language') }}</div>
                            <div class="header_menu_mobile_block_lang">
                                <a
                                    v-for="(lang, indexLang) in menu?.languages"
                                    :key="indexLang"
                                    :item="lang"
                                    :href="switchLocalePath(lang.code)"
                                    class="header_menu_block_lang_item"
                                    @click="updateLang(lang)"
                                >
                                    <span
                                        :class="{
                                            header_menu_block_lang_item_active: langStore.langCode === lang.code,
                                        }"
                                        class="header_menu_mobile_lang"
                                    >
                                        <img :src="runtimeConfig.public.STATIC_BASE_URL + lang.icon" alt="" class="header_menu_block_lang_item_image" />
                                        {{ lang.name }}
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div class="header_menu_block_link header_menu_block_lang">
                            <transparent-select @return-option="lang => updateLang(lang.code)">
                                <template #title>
                                    <div class="default_drowndown_title d-flex align-items-center">
                                        <div class="header_menu_icons_block">
                                            <img :src="runtimeConfig.public.STATIC_BASE_URL + activeLang.icon" alt="" class="header_menu_icons header_menu_icons_flug" />
                                        </div>
                                        {{ activeLang.abbreviation }}
                                        <div class="header_menu_icons_block">
                                            <img src="@/assets/images/arrow_done.svg" alt="" class="header_menu_icons header_menu_icons_arrow_done" />
                                        </div>
                                    </div>
                                </template>
                                <transparent-option v-for="(lang, indexLang) in menu?.languages" :key="indexLang" :item="lang">
                                    <div class="default_drowndown_item_block d-flex">
                                        <div class="default_drowndown_content_item_lang_image_block">
                                            <img class="header_menu_icons_block" :src="runtimeConfig.public.STATIC_BASE_URL + lang.icon" alt="" />
                                        </div>
                                        <span class="default_drowndown_content_item_title">
                                            {{ lang.abbreviation }}
                                        </span>
                                    </div>
                                </transparent-option>
                            </transparent-select>
                        </div>
                        <div class="header_menu_block_link">
                            <a class="header_menu_link" @click="() => openModalSupport()">
                                <div class="header_menu_icons_block">
                                    <img src="@/assets/images/default_support_icon.svg" alt="" class="header_menu_icons" />
                                </div>
                                {{ menu?.supportText }}
                            </a>
                        </div>
                        <div class="overflow header_menu_block_link">
                            <a class="header_menu_link header_menu_link_telephone" :href="'tel:' + (countryStore.currentCity.tel_number || menu.tel_number)">
                                {{ countryStore.currentCity.tel_number || menu.tel_number }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-none d-lg-block">
                <hr class="hr" />
                <div class="container-xl">
                    <PageList />
                </div>
            </div>
        </div>
    </header>
</template>

<script setup>
import { ref } from 'vue';
import transparentSelect from '~~/components/Select/TransparentSelect/TransparentSelect.vue';
import transparentOption from '~~/components/Select/TransparentSelect/TransparentOption.vue';
import defaultInput from '~~/components/Input/DefaultInput.vue';
import { useContentPages } from '~~/stores/homeStores';
import { isProductCode } from '@/pages/index/utils/isProductCode';
import PageList from '~/components/Header/defaultHeader/PageList.vue';
import { useTranslateLayOut } from '~/stores/translateStore.ts';
import { useLang } from '~/stores/langStore';

const props = defineProps({
    menu: {
        type: Object,
        default: () => ({}),
    },
});
const { setLocale, setLocaleCookie } = useI18n();
const contentPagesStore = useContentPages();
const layoutStore = useTranslateLayOut();
const countryStore = useCountry();
const langStore = useLang();

const searchFormCode = ref('');
const searchFormCodeLoading = ref(false);
const runtimeConfig = useRuntimeConfig();
const searchFormCodeResult = reactive({
    success: null,
    text: layoutStore.translateData.cargo_search_placeholder.value || 'Введите код чтобы найти груз',
});

const menuStatus = ref(false);
const activeLang = computed(() => langStore.langOptions);
const switchLocalePath = useSwitchLocalePath();

const updateStatusMenu = status => {
    menuStatus.value = status;
};

const computedSelectedCity = computed(() => countryStore.currentCity.name || 'Выберите город');

const searchProductCode = async code => {
    searchFormCodeLoading.value = true;
    const result = await isProductCode(code);
    searchFormCodeLoading.value = false;

    if (result.status === 'error') {
        searchFormCodeResult.success = false;
    } else {
        searchFormCodeResult.success = true;
        window.location.href = `https://pecom.ru/services-are/order-status/?code=${codeRaw}`;
    }
};

const openMenu = () => {
    updateStatusMenu(true);
    getBody().style.overflowY = 'hidden';
};

const closeMenu = () => {
    updateStatusMenu(false);
    getBody().style.overflowY = null;
};

const updateLang = async lang => {
    contentPagesStore.setLangLoading(true);
    closeMenu(false);
    setLocaleCookie(lang);
    setLocale(lang);
};

const getLogoType = type => {
    return props.menu.logo.find(logo => logo.type === type);
};

const getBody = () => {
    return document.querySelector('body');
};

const openModalRegionSelect = () => {
    const body = document.querySelector('body');
    body.style.overflowY = 'hidden';
    contentPagesStore.updateStatusRegionSelect(true);
};

const openModalSupport = async () => {
    contentPagesStore.updateStatusSupport(true);
    const body = document.querySelector('body');
    body.style.overflowY = 'hidden';
};
</script>

<style>
.header_content_block {
    top: 0;
    position: fixed;
    background: white;
    z-index: 110;
}

.hr {
    border-bottom: 1px solid #e4e6e7;
}
header {
    width: 100%;
    height: 7.875rem;
}
.header-content {
    padding-top: 0.68rem;
}
.header_logo_block {
    width: 40%;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.header_menu_block {
    width: 60%;
}

.header_content_block {
    width: 100%;
    height: 7.875rem;
}

.header_content_info_calculate_result_text {
    margin-left: 10px;
}

.header_logo_blue {
    display: none;
}

.header_menu_mobile_row {
    display: none;
}

.header_menu_block_link {
    display: flex;
    align-items: center;
    height: 100%;
}

.header_menu_block_link:not(:last-child) {
    margin-right: 40px;
}

.header_menu_link {
    display: flex;
    text-decoration: none;
    color: #2b2b2b;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
}

.header_menu_icons_block {
    margin: 0px 10px;
}

.header_menu_mobile_block-row {
    display: none;
}

.header_menu_icons {
    width: 12px;
    background-size: contain;
}

.header_menu_icons_flug {
    width: 24px;
    height: 24px;
}

.header_menu_link_telephone {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
}

.header_menu_icons_arrow_done {
    width: 16px;
}

.header_content_info_preview img {
    /* width: 100%; */
    width: 740px;
    object-fit: contain;
}

.header_content_info_forms {
    margin-top: 16px;
}

.header_content_info_forms_title {
    font-weight: 500;
    text-align: left;
    color: #2b2b2b;
    font-size: 24px;
}

.header_content_info_calculate_block {
    margin-top: 80px;
}

.header_content_info_calculate_form_input_size {
    width: 240px;
}

.header_content_info_calculate_forms_block {
    margin-top: 16px;
}

.header_content_info_calculate_button {
    margin-top: 25px;
}

.header_content_info_calculate_result {
    margin-top: 16px;
    margin-left: 10px;
}
.header_menu_block_lang_item_active {
    background: #e5e5e589;
}

.header_content_advantages_block {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 40px;
}

.header_menu_burger_block {
    display: none;
}

.header_content_advantages_info_block {
    display: flex;
    border-top: 1px solid #e4e6e7;
    padding-top: 24px;
}

.header_menu_group_block {
    display: flex;
    justify-content: center;
}

.header_menu_group_active {
    left: 0 !important;
}

.default_drowndown_content {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    z-index: 1000;
    background: #ffffff;
}

.default_drowndown_content_item {
    padding: 5px;
}

.default_drowndown_item_block {
    color: #2b2b2b;
    text-decoration: none;
    padding: 5px 0px;
    cursor: pointer;
}

.default_transparent_select_content_block {
    background: white;
}

.header_menu_close_button_block {
    display: none;
    width: 100%;
}

.header_menu_close_button_image {
    width: 100%;
}

.header_menu_mobile_block .header_menu_close_button {
    width: 20px;
}

.header_menu_close_button {
    width: 20px;
}

.default_drowndown_title {
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
}

.default_drowndown_content_item_title {
    font-size: 14px;
}

.header_menu_block_link_mobile {
    display: none;
}

.header_city_select_mobile_prefix {
    display: none;
}

@media (max-width: 1400px) {
    .header_content_info_preview > img {
        width: 100%;
    }
}

@media (max-width: 1200px) {
    .header_menu_block {
        padding: 0.68em 16px 0;
    }
}

@media (max-width: 1159px) {
    .header_content_advantages_info_block {
        display: block;
    }

    .header_content_advantages_number_block {
        margin: 0 0 24px;
    }
}

@media (max-width: 1120px) {
    .header_logo_block {
        width: 30%;
    }

    .header_menu_block {
        width: 70%;
    }

    .header_content_info_calculate_form_input_size {
        min-width: 210px;
    }
    .header_content_block {
        width: 100%;
        height: 7.875rem;
    }
}

@media (max-width: 1000px) {
    .header_content_info_preview > img {
        width: 100%;
    }

    .header_content_info_calculate_form_input_size {
        width: 100%;
    }

    .header_content_info_calculate_form_icons {
        margin: 15px;
        cursor: pointer;
    }
    .header_content_block {
        width: 100%;
        height: 80px;
    }
}

@media (max-width: 912px) {
    html,
    body {
        overflow-x: hidden;
        position: relative;
    }
    header {
        height: 100%;
    }

    .header_content_block {
        width: 100%;
        height: 50px;
        background: #252069;
        border-bottom: none;
    }
    .front-direction-field-new_control-wrapper {
        width: 100%;
    }
    .header_content_advantages_text {
        font-size: 18px;
        max-width: 100%;
        width: 100%;
    }
    .header_content_info_preview > img {
        width: 740px;
    }
    .header_logo_block > img {
        width: 80px;
    }

    .header_menu_block {
        padding: 0;
        height: 52px;
    }

    .header_menu_block_link {
        margin: 20px;
    }

    .header_menu_group_block {
        background: #ffffff;
        display: block;
        position: fixed;
        top: 0;
        left: 100%;
        width: 100%;
        height: 100%;
        z-index: 1000;
    }

    .header_menu_block_link {
        height: auto;
    }

    .header_menu_close_button_block {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 50px;
    }

    .header_logo_white {
        display: none;
    }

    .header_logo_blue {
        display: block;
    }

    .header_content_info_calculate_form_input_size {
        min-width: 340px;
    }
    .header_menu_burger_block {
        display: flex;
    }

    .header_menu_burger_block img {
        width: 24px;
        margin: 0 20px;
    }

    .header_content_advantages_info_block {
        display: block;
    }

    .header_menu_mobile_logo_block {
        width: 50%;
        display: flex;
        align-items: center;
    }

    .header_menu_mobile_row {
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }

    .header_menu_close_button_block {
        width: 50%;
    }

    .header_menu_mobile_logo {
        width: 80px;
    }

    .header_menu_mobile_block {
        display: block;
    }

    .header_menu_mobile_block-row {
        display: block;
    }

    .header_menu_mobile_block {
        display: flex;
    }

    .header_menu_block_link_mobile {
        display: block;
    }

    .header_menu_block_lang_title {
        font-size: 16px;
        border-bottom: 2px solid #e4e6e7;
    }

    .header_menu_block_lang_item {
        margin-top: 20px;
    }

    .header_menu_block_lang {
        display: none;
    }

    .header_menu_block_link {
        margin: 20px 0px 20px 10px;
    }

    .header_menu_icons_block {
        margin: 0px 10px 0px 0px;
    }

    .header_city_select_icon {
        display: none;
    }

    .header_city_select_mobile_prefix {
        display: block;
        margin-right: 10px;
    }

    .header_city_select_selected_city {
        color: #48538b;
    }

    .header_menu_link:hover {
        background: #e5e5e589;
    }
}

@media (max-width: 768px) {
    .header_content_info_preview > img {
        width: 737px;
    }
}

@media (max-width: 740px) {
    .header_content_info_preview > img {
        width: 100%;
        min-width: 370px;
    }
}

@media (max-width: 580px) {
    .header_menu_burger_block img {
        margin: 0 20px;
    }
}
</style>
