<template>
    <div v-on-click-outside="handleClickOutside" class="default_transparent_select_block">
        <div class="default_transparent_select_title" @click="updateAciveContentStatus(!isActiveContent)">
            <slot name="title" />
        </div>
        <div v-if="isActiveContent" class="default_transparent_select_content_block">
            <slot />
        </div>
    </div>
</template>

<script setup lang='ts'>
import { provide, ref } from 'vue';
import { vOnClickOutside } from '@vueuse/components';

defineProps({});
const emits = defineEmits(['returnOption']);
const isActiveContent = ref(false);

const handleClickOutside = (): void => {
    isActiveContent.value = false;
};

const updateAciveContentStatus = (status): void => {
    isActiveContent.value = status;
};

const emitSelect = (item): void => {
    emits('returnOption', item);
    updateAciveContentStatus(false);
};

provide('transparentSelect', {
    emitSelect,
});
</script>

<style scoped>
.default_transparent_select_block {
    position: relative;
}

.default_transparent_select_content_block {
    width: 100%;
    padding: 20px 0px;
    position: absolute;
    top: 100%;
    z-index: 100;
}
</style>
