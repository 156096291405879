<template>
    <div class="default_transparent_option_block" @click="emitSelect(item)">
        <div class="default_transparent_option_item">
            <slot />
        </div>
    </div>
</template>

<script setup>
import { inject } from 'vue';

defineProps({
    item: {
        type: Object,
        default: () => ({}),
    },
});

const { emitSelect } = inject('transparentSelect');
</script>

<style></style>
