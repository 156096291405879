<template>
    <div class="default_keyboard_option_block" @click="test">
        <slot />
    </div>
</template>

<script setup lang='ts'>
import { inject } from 'vue';

const props = defineProps<{ value: string | object }>();

const test = (): void => {
    selection.selectionsAction(props.value);
};
const selection = inject('defaultKeyboardLayout');
</script>

<style scoped>
.default_keyboard_option_block {
    margin-bottom: 12px;
}
</style>
