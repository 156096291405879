<template>
    <div class="default_keyboard_layout_block">
        <div class="default_keyboard_layout">
            <slot />
        </div>
    </div>
</template>

<script setup lang='ts'>
import { ref, provide } from 'vue';

const props = defineProps<{ checkAction: string | object }>();
const result = ref(null);
const resultEmit = defineEmits(['result']);
const selectionsAction = (value): void => {
    switch (props.checkAction) {
        case 'default':
            result.value = value;
    }
    resultEmit('result', result);
};

provide('defaultKeyboardLayout', {
    selectionsAction: selectionsAction,
});
</script>

<style scoped>
.default_keyboard_layout {
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 768px) {
    .default_keyboard_layout_block {
        display: none !important;
    }
}
</style>
