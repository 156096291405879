<template>
    <div class="support_block" @click="closeModal">
        <div class="support w-100">
            <div class="support_grid">
                <div class="support__mail_image support_column">
                    <img src="@/assets/images/support_mail.svg" alt="" class="header_menu_icons" />
                </div>
                <div class="support__mails support_column">
                    <div class="support_header">
                        {{ data.titles[0].content }}
                    </div>
                    <div class="support_column_items">
                        <a
                            v-for="(contentMessageType, contentMessageIndex) in data.message_communication"
                            :key="contentMessageIndex"
                            class="support_column_item"
                            :href="'mailto:lidsp@pecom.ru?subject=' + contentMessageType.content"
                        >
                            <span class="support_column_item_text">{{ contentMessageType.content }}</span>
                        </a>
                    </div>
                </div>
                <div class="support__social support_column">
                    <div class="support_header">
                        {{ data.titles[1].content }}
                    </div>
                    <div class="support_column_items">
                        <a v-for="(otherItemLink, otherIndex) in data.other_communication" :key="otherIndex" :href="otherItemLink.link" class="support_column_item">
                            <span class="support_column_item_image">
                                <img :src="runtimeConfig.public.STATIC_BASE_URL + otherItemLink.icon" alt="" />
                            </span>
                            <span class="support_column_item_text">{{ otherItemLink.name }}</span>
                        </a>
                    </div>
                </div>
                <div class="support__phone support_column support_column_items">
                    <div class="support_header">
                        {{ data.titles[2].content }}
                    </div>
                    <div class="support_column_item support_phone_item">
                        {{ phoneSupport }}
                    </div>
                </div>
            </div>
            <div
                class="support_header_close_button_block"
                @click="
                    emitActions({
                        actionName: 'closeSupport',
                        value: true,
                    })
                "
            >
                <img src="@/assets/images/icons/arrow_done.svg" alt="" class="support_header_close_button" />
            </div>
        </div>
    </div>
</template>

<script setup>
const runtimeConfig = useRuntimeConfig();
</script>

<script>
import { vOnClickOutside } from '@vueuse/components';
import { useContentPages } from '~/stores/homeStores';

export default {
    directives: {
        vOnClickOutside,
    },
    props: {
        data: {},
    },
    data() {
        return {
            pageStore: useContentPages(),
            countryStore: useCountry(),
        };
    },
    computed: {
        phoneSupport() {
            return this.countryStore.currentCity?.tel_number;
        },
    },
    mounted() {},
    methods: {
        emitActions(action) {
            this.$emit('closeButton', action);
        },
        closeModal(event) {
            if (event.target.className.includes('support_block')) {
                this.emitActions({
                    actionName: 'closeSupport',
                    value: true,
                });
            }
        },
    },
};
</script>

<style>
.support_block {
    background: #0000003b;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 110;
    left: 50%;
    transform: translate(-50%, 0);
    box-shadow: 0 0 0 50vmax rgba(0, 0, 0, 0.5);
}

.support {
    display: flex;
    justify-content: center;
    min-height: 350px;
    background: #fff;
    padding: 30px;
}

.support_grid {
    display: grid;
    width: 1280px;
    column-gap: 24px;
    grid-template-columns: repeat(4, auto);
}

.support_header_close_button_block {
    display: flex;
    justify-content: flex-end;
}

.support_column {
    width: 100%;
    margin-top: 30px;
}

.support_header_close_button {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.support_header {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 16px;
}

.support_column_items {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.support_column_item {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 12px;
    text-decoration: none;
    color: #2e2e2e;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
}

.support__phone {
    gap: 0;
}

.support_column_item.support_phone_item {
    font-size: 24px;
    line-height: 28px;
}

.support__mail_image {
    width: 100px;
    height: 100px;
    border-radius: 6px;
    border: 1px solid #e4e6e7;
    display: flex;
    justify-content: center;
    align-items: center;
}

.support__mail_image img {
    width: 100%;
}

@media (max-width: 1000px) {
    .support_grid {
        display: grid;
        width: 1280px;
        column-gap: 24px;
        grid-template-columns: repeat(2, auto);
    }
}

@media (max-width: 550px) {
    .support_block {
        overflow-y: scroll;
        height: 100%;
    }

    .support_grid {
        display: grid;
        column-gap: 24px;
        grid-template-columns: repeat(1, auto) !important;
    }
}
</style>
