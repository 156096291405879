<template>
    <div class="home-view">
        <div class="header-wrapper">
            <div id="header" class="header_content_block w-100">
                <default-header :menu="contentPagesData?.header" />
            </div>
        </div>

        <slot />

        <selected-region v-if="contentPagesStore?.statusRegionSelect" :data="contentPagesData" @close-button="() => closeModalRegion()" />
        <support v-if="contentPagesStore.supportShow" :data="contentPagesData?.contactBlock" @close-button="() => closeModalSupport()" />
        <loader v-if="loader" />
        <div class="mt-auto">
            <default-footer>
                <template #block_right>
                    <div class="footer_content_downloads_market_title_block">
                        <span class="footer_content_downloads_market_title">
                            {{ contentPagesData?.footer?.store_link_title }}
                        </span>
                    </div>
                    <div class="footer_content_downloads_market_link_group_block d-flex">
                        <div v-for="(marketLinkItem, marketLinkIndex) in contentPagesData?.footer?.store_links" :key="marketLinkIndex" class="footer_content_downloads_market_link_group_item">
                            <a :href="marketLinkItem.link" target="_blank" class="footer_content_downloads_market_link">
                                <img :src="runtimeConfig.public.STATIC_BASE_URL + marketLinkItem.icon" alt="" class="footer_content_downloads_market_link_image" />
                            </a>
                        </div>
                    </div>
                </template>
                <template #block_left>
                    <img v-if="contentPagesData?.footer?.logo" :src="runtimeConfig.public.STATIC_BASE_URL + contentPagesData?.footer?.logo" alt="" class="footer_content_logo" @click="scrollToTop()" />
                    <div class="footer_content_logo_info_pesonal">
                        <div class="footer_content_logo_info_pesonal_text_block">
                            <span class="footer_content_logo_info_pesonal_text">
                                {{ new Date().getFullYear() }}
                                {{ contentPagesData?.footer?.copyright }}
                            </span>
                        </div>
                        <div class="footer_content_logo_info_pesonal_link_block">
                            <a href="#" class="footer_content_logo_info_pesonal_link">
                                {{ contentPagesData?.footer?.personal_data }}
                            </a>
                        </div>
                    </div>
                </template>
            </default-footer>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useContentPages } from '@/stores/homeStores';
import { liveText } from '@/utils/liveTex';

import SelectedRegion from '~~/components/ModalWindow/SelectedRegion.vue';
import Support from '~~/components/ModalWindow/Support.vue';
import DefaultHeader from '~~/components/Header/defaultHeader/DefaultHeader.vue';
import DefaultFooter from '~~/components/Footer/DefaultFooter.vue';
import { useLayout } from '~/stores/layoutStore.ts';
const runtimeConfig = useRuntimeConfig();
const loader = ref(false);

onMounted(async () => {
    if (process.client) {
        liveText();
    }
});

const contentPagesStore = useContentPages();
const layoutStore = useLayout();

const contentPagesData = computed(() => layoutStore.dataPageLayout);

const closeModalSupport = () => {
    contentPagesStore.updateStatusSupport(false);
    const body = document.querySelector('body');
    body.style.overflowY = null;
};

const closeModalRegion = () => {
    const body = document.querySelector('body');
    body.style.overflowY = null;
    contentPagesStore.updateStatusRegionSelect(false);
};

const scrollToTop = () => {
    document.querySelector('body').scrollIntoView();
};
</script>

<style>
html,
body {
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
    font-family: 'Roboto';
    color: #2b2b2b;
    min-height: 100vh;
}
.home-view {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
.main-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
}
.main-layout-content {
    margin-bottom: auto;
}
.default_border_block {
    position: absolute;
    width: 100%;
    height: 100%;
}
.default_border {
    width: 40px;
    height: 40px;
    position: absolute;
}
.default_border_top_left {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-top: 1px solid #aaa;
    border-left: 1px solid #aaa;
}
.default_border_top_right {
    position: absolute;
    top: 0;
    right: 0;
    border-top: 1px solid #aaa;
    border-right: 1px solid #aaa;
}
.default_border_bottom_left {
    bottom: 0;
    left: 0;
    border-bottom: 1px solid #aaa;
    border-left: 1px solid #aaa;
}
.default_border_bottom_right {
    bottom: 0;
    right: 0;
    border-bottom: 1px solid #aaa;
    border-right: 1px solid #aaa;
}
.full-height {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}
.link-with-arrow {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    color: #48538b;
    font-size: 14px;
    line-height: 1;
}
.link-with-arrow::after {
    content: url('../assets/images/icons/link_arrow.svg');
    height: 1rem;
}
.link-with-arrow.reversed::after {
    display: none;
}
.link-with-arrow.reversed::before {
    content: url('../assets/images/icons/link_arrow.svg');
    height: 1rem;
    transform: rotate(180deg);
}
</style>
