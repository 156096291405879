<template>
    <div class="w-100 bg-white">
        <div class="page-list">
            <NuxtLink v-for="item in pagesWithUrls" :key="item.position" :to="item.url" class="link"> {{ item.name }}</NuxtLink>
        </div>
    </div>
</template>
<script setup lang="ts">
import { useLayout } from '~/stores/layoutStore';
import { storeToRefs } from 'pinia';

const layoutPageStore = useLayout();
const { pages } = storeToRefs(layoutPageStore);
const sortedPages = computed(() => pages.value.sort((a, b) => a.position - b.position));
const { locale } = useI18n();
const pagesWithUrls = computed(() => sortedPages.value.map(({ url, ...other }) => ({ url: locale.value === 'kk' ? url : `/${locale.value}${url}`, ...other })));
</script>

<style scoped lang="scss">
.page-list {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding: 0.63rem 0;
    background: white;
}
.link {
    color: var(--main-black-2-b-2-b-2-b, #2b2b2b);
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem; /* 125% */
    text-decoration: none;
}
</style>
