/**
 * Initializes and configures the LiveTex chat widget.
 * Adjusts the widget's appearance based on the window size and sets the visitor's name using local storage data.
 * The function sets up event listeners to handle window resizing and updates the LiveTex widget accordingly.
 * It fetches the user's name from local storage and sets it in the LiveTex widget as the visitor's name.
 */
export const liveText = () => {
    let username = '';
    if (localStorage.getItem('lk-user-firstname') || localStorage.getItem('lk-user-login')) {
        username = localStorage.getItem('lk-user-firstname') ? localStorage.getItem('lk-user-firstname') : localStorage.getItem('lk-user-login');
    }
    window.LiveTex = {
        /**
         * Configures the LiveTex widget when it's ready. This includes setting up the widget's
         * appearance based on the current window size and registering event listeners for
         * window resize and other interactions. Also sets the user's name in the widget from local storage.
         */
        onLiveTexReady: function () {
            const liveTexNode = [].slice.call(document.getElementsByTagName('iframe')).find(iframe => iframe.src.includes('https://widget-omnibutton.livetex.ru/widget.html'));
            const updateLiveTex = () => {
                if (window.innerWidth < 1475) {
                    liveTexNode.style.display = 'block';
                    liveTexNode.style.transform = 'scale(0.56)';
                    liveTexNode.style.right = '-25px';
                    liveTexNode.style.top = 'unset';
                    liveTexNode.style.left = 'unset';
                } else {
                    liveTexNode.style.display = 'block';
                    liveTexNode.style.transform = 'scale(1)';
                    liveTexNode.style.right = '10px';
                    liveTexNode.style.top = 'unset';
                    liveTexNode.style.left = 'unset';
                }
                if (window.innerWidth <= 1024) {
                    liveTexNode.style.display = 'none';
                }
            };

            updateLiveTex();
            window.addEventListener('resize', () => {
                updateLiveTex();
            });
            window.addEventListener('SiteToolbarNavigationItem.toggle', () => {
                updateLiveTex(true);
            });

            LiveTex.setVisitorAttributes(
                function () {},
                function () {},
                {
                    name: {
                        name: username,
                        is_editable: true,
                    },
                    contacts: [],
                }
            );
        },
    };
};
