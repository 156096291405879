<template>
    <div class="choosing_region_block">
        <div class="choosing_region">
            <div class="choosing_region_header_block choosing_region_header_block_row">
                <div class="choosing_region_header_title_block d-flex align-items-start choosing_region_header_block_col">
                    <span class="choosing_region_header_title">{{ translateData.region_selection.value }}</span>
                </div>
                <div
                    class="choosing_region_header_close_button_block d-flex align-items-center justify-content-end choosing_region_header_block_col"
                    @click="emitActions({ actionName: 'closeModal', value: true })"
                >
                    <img src="@/assets/images/icons/arrow_done.svg" alt="" class="choosing_region_header_close_button" />
                </div>
            </div>
            <div class="choosing_region_content_block">
                <div class="choosing_region_content_search_block">
                    <default-input v-model="currentCity" :placeholder="translateData.city_selector_text.value" :title="true" class="choosing_region_content_search" @change="changeTextInputHandler">
                        <template #container-right>
                            <img class="choosing_region_content_search_icon_button" src="@/assets/images/search_icon.svg" alt="" @keyup.enter="getCountry(currentCity)" />
                        </template>
                    </default-input>
                </div>
                <div class="choosing_region_content_territory_block">
                    <div class="choosing_region_content_territory d-flex">
                        <div class="choosing_region_content_city_list_block">
                            <div class="choosing_region_content_city_list">
                                <div
                                    v-for="(country, countryIndex) in list"
                                    :key="countryIndex"
                                    :class="{
                                        choosing_region_content_city_list_item_active: country.id === currentCountry?.id,
                                    }"
                                    class="choosing_region_content_city_list_item"
                                    @click="updateCurrentCountry(country)"
                                >
                                    {{ country.country }}
                                </div>
                                <div class="choosing_region_content_city_list_item"></div>
                            </div>
                        </div>
                        <div class="choosing_region_content_city_block_info">
                            <div class="choosing_region_content_city_block_popular">
                                <div class="choosing_region_content_city_block_popular_title_block">
                                    <span class="choosing_region_content_city_block_popular_title_block">
                                        {{ translateData.popular_cities.value }}
                                    </span>
                                </div>
                                <div class="choosing_region_content_city_block_popular_list_block">
                                    <div class="choosing_region_content_city_block_popular_list d-flex">
                                        <div v-for="(country, countryIndex) in popularCities" :key="countryIndex" class="choosing_region_content_city_block_popular_list_item_block">
                                            <a class="choosing_region_content_city_block_popular_list_item" @click="updateCurrentCity(country)">
                                                {{ country.name }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="choosing_region_content_city_block_popular_keyboard_block">
                                    <default-keyboard-layout check-action="default" class="d-flex">
                                        <div class="choosing_region_content_city_block_popular_keyboard_button_block"></div>
                                        <default-keyboard-option v-for="(item, index) in letters" :key="index" :value="item">
                                            <div
                                                :class="`choosing_region_content_city_block_popular_keyboard_button ${
                                                    item.selected ? 'choosing_region_content_city_block_popular_keyboard_button_selected' : ''
                                                }`"
                                                @click="clickLetterHandler(item)"
                                            >
                                                {{ item.name }}
                                            </div>
                                        </default-keyboard-option>
                                    </default-keyboard-layout>
                                </div>
                                <div class="choosing_region_content_city_all_list_block">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="choosing_region_content_city_all_list_item"></div>
                                        </div>
                                        <div class="col-6"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="result_city_block">
            <div class="row">
                <div class="col-6"></div>
                <div class="col-6"></div>
            </div>
        </div>
    </div>
</template>

<script>
import defaultInput from '~~/components/Input/DefaultInput.vue';
import defaultKeyboardLayout from '~~/components/Keyboard/DefaultKeyboardLayout.vue';
import defaultKeyboardOption from '~~/components/Keyboard/DefaultKeyboardOption.vue';
import { getCountrySearch } from '~~/api/getCountry/getCountry';
import { useContentPages } from '@/stores/homeStores';
import { useLang } from '~/stores/langStore';

export default {
    components: {
        defaultInput,
        defaultKeyboardLayout,
        defaultKeyboardOption,
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        const kzABC = 'аәбвгғдеёжзийкқлмнңоөпрстуұүфхһцчшщъыіьэюя';
        const ruABC = 'абвгдеёжзийклмнопрстуфхцчшщъыьэюя';
        const engABC = 'abcdefghijklmnopqrstuvwxyz';
        const contentPagesStore = useContentPages();
        const countryStore = useCountry();
        const langStore = useLang();
        let alphabet = [];

        switch (langStore.langCode) {
            case 'kk':
                alphabet = kzABC.toUpperCase().split('');
                break;
            case 'en':
                alphabet = engABC.toUpperCase().split('');
                break;
            case 'ru':
                alphabet = ruABC.toUpperCase().split('');
                break;
        }

        const listLetters = alphabet.reduce((acc, item, currentIndex) => {
            acc = [
                ...acc,
                {
                    id: currentIndex,
                    name: item,
                    selected: false,
                },
            ];
            return acc;
        }, []);

        const currentCountry = countryStore.getSelectedCountry;
        const translateStore = useTranslateLayOut();
        const popularCities = currentCountry.territoryList?.sort((currentItem, nextItem) => currentItem.rating + nextItem.rating);

        return {
            letters: listLetters,
            selectedLetter: {},
            list: countryStore.countryList,
            popularCities,
            currentCity: '',
            currentCountry,
            contentPagesStore,
            countryStore,
            translateStore,
            langStore,
        };
    },
    computed: {
        translateData() {
            return this.translateStore.translateData;
        },
    },
    mounted() {
        getCountrySearch('', useLang().langCode);
    },
    methods: {
        clickLetterHandler(value) {
            this.selectedLetter = value;
            this.letters = this.letters.map(item => {
                item.selected = item.name === value.name;
                return item;
            });
            const request = getCountrySearch(value.name, this.langStore.langCode);

            request.then(result => {
                if (result.data.length) {
                    this.popularCities = result.data;
                } else {
                    this.popularCities = this.currentCountry?.territoryList?.sort((currentItem, nextItem) => currentItem.rating + nextItem.rating);
                }
            });
        },
        changeTextInputHandler(e) {
            this.letters = this.letters.map(item => {
                return { ...item, selected: false };
            });
            this.selectedLetter = {};
            const request = getCountrySearch(e.target.value, this.langStore.langCode);

            request.then(result => {
                if (result.data.length) {
                    this.popularCities = result.data;
                } else {
                    this.popularCities = this.currentCountry?.territoryList?.sort((currentItem, nextItem) => currentItem.rating + nextItem.rating);
                }
            });
        },
        updateCurrentCountry(country) {
            this.currentCountry = country;
            this.popularCities = country.territoryList?.sort((currentItem, nextItem) => currentItem.rating + nextItem.rating);
            this.contentPagesStore.changeCountry(country);
        },
        updateCurrentCity(city) {
            this.countryStore.changeCity(city);
            this.emitActions({
                actionName: 'closeModal',
                value: true,
            });
        },
        getCountry(value) {
            getCountrySearch(value);
        },
        emitActions(action) {
            this.$emit('closeButton', action);
        },
    },
};
</script>

<style>
.choosing_region_block {
    background: #fff;
    height: 602px;
    position: fixed;
    top: 2rem;
    width: 867px;
    z-index: 110;
    left: 50%;
    transform: translate(-50%, 0);
    box-shadow: 0 0 0 50vmax rgba(0, 0, 0, 0.5);
}
.choosing_region {
    padding: 30px;
}
.choosing_region_header_block {
    display: flex;
}
.choosing_region_header_block_row {
    width: 100%;
}
.choosing_region_header_block_col {
    width: 50%;
}
.choosing_region_header_title_block {
    font-size: 24px;
    font-weight: 500;
    color: #2b2b2b;
    white-space: nowrap;
}
.choosing_region_header_close_button {
    width: 18px;
    height: 18px;
    cursor: pointer;
}
.choosing_region_content_search_block {
    margin-top: 30px;
}
.choosing_region_content_search_icon_button {
    cursor: pointer;
}
.choosing_region_content_search_icon_button:hover {
    filter: invert(59%) sepia(57%) saturate(2936%) hue-rotate(211deg) brightness(104%) contrast(106%);
}
.choosing_region_content_territory_block {
    width: 100%;
    margin-top: 20px;
}
.choosing_region_content_city_list_block {
    min-width: 160px;
    width: 200px;
    height: 100%;
    border-right: 1px solid #e4e6e7;
}
.choosing_region_content_city_block_popular_list_block {
    margin-top: 20px;
}
.choosing_region_content_city_block_popular_list {
    flex-wrap: wrap;
}
.choosing_region_content_city_list {
    margin-right: 20px;
}
.choosing_region_content_city_block_popular_keyboard_button:hover {
    background: #48538b;
    color: white;
}
.choosing_region_content_city_list_item {
    cursor: pointer;
    padding: 8px 16px;
    margin-bottom: 20px;
}
.choosing_region_content_city_list_item_active {
    background: #f6f6f6;
}
.choosing_region_content_city_block_popular {
    margin-left: 40px;
}
.choosing_region_content_city_block_info {
    width: 100%;
}
.choosing_region_content_city_block_popular_title_block {
    font-size: 1.4em;
    color: #2b2b2b;
    font-weight: 500;
}
.choosing_region_content_city_block_popular_list_item_block {
    margin-right: 30px;
}
.choosing_region_content_city_block_popular_list_item_block:hover {
    background: #f6f6f6;
}
.choosing_region_content_city_block_popular_list_item {
    text-decoration: none;
    color: #48538b;
}
.choosing_region_content_city_block_popular_keyboard_block {
    margin-top: 30px;
}
.choosing_region_content_city_block_popular_keyboard_button {
    padding: 4px 8px;
    margin-right: 4px;
    background: #e4e6e7;
    border-radius: 3px;
    cursor: pointer;
    margin-bottom: 12px;
}

.choosing_region_content_city_block_popular_keyboard_button_selected {
    background: #48538b;
    color: white;
}
.choosing_region_content_city_block_info {
    overflow-y: scroll;
}
.choosing_region_content_city_block_info::-webkit-scrollbar-thumb {
    background-color: #aaaaaa;
    border-radius: 3px;
    border: var(--scrollbarBorder);
}
.choosing_region_content_city_block_info::-webkit-scrollbar {
    width: 12px;
}
.choosing_region_content_city_block_info::-webkit-scrollbar-track {
    background: #e4e6e7;
}

.choosing_region_content_city_block_info {
    overflow-y: hidden;
}

@media (max-width: 1000px) {
    .choosing_region_block {
        width: 100%;
        height: 100%;
        top: 0;
    }
}

@media (max-width: 768px) {
    .default_keyboard_layout_block {
        display: none !important;
    }
    .choosing_region_content_city_block_popular_list_item_block {
        margin-top: 16px;
    }
    .choosing_region_content_city_block_popular_title_block {
        font-size: 20px;
    }
    .choosing_region_content_city_block_popular_list {
        display: block !important;
    }
}

@media (max-width: 480px) {
    .choosing_region_content_city_list_block {
        display: none;
    }

    .choosing_region_content_city_block_popular {
        margin: 0;
    }
}
</style>
